import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';

import { Sections } from '@/section/Sections';
// import { NewSection } from '@/section/NewSection';
import { BiPlus } from "react-icons/bi";
import { PageNav } from '@/page/PageNav';
import { GlobalMenus } from '@/editerMenu/GlobalMenus';

/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { productAtom, openSidebarAtom, sharingAtom, sectionIndexAtom, sectionsStatesAtom, pageWidthAtom } from '@/providers/Atoms';


export const ShareSections = React.memo(({ sharing }) => {
  
  const { productId } = useParams();
  const { client } = useContext(StateContext);
  const [ sections, setSections ] = useState([]);
  const [ setting, setSetting ] = useState([]);
  const setProduct = useSetRecoilState(productAtom)
  
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  const setSharing = useSetRecoilState(sharingAtom);
  const setSectionIndex = useSetRecoilState(sectionIndexAtom);
  const setSectionsStates = useSetRecoilState(sectionsStatesAtom);
  const [ pageWidth ] = useRecoilState(pageWidthAtom);
  
  useEffect(() => {
    client.get(`/api/v1/sections/sharing?subdomain=${productId}&sharing=${sharing}`)
    .then(resp => {
      setProduct(resp.data.product);
      setSections(resp.data.sections);
      setSetting(resp.data.setting);
      setOpenSidebar("");
    })
  }, []);
  
  
  const SettingCSS = css`
   font-family: ${setting.font};
   background: ${setting.back_color};
   font-size: ${setting.font_size && `${setting.font_size}px`};
  `
  
  // const [ open, setOpen ] = useState(false);
  // const OpenHandler = ()=> {
  //   setOpen(true);
  // };

  // セクション追加サイドバーを表示
  const newSectionHandler = () => {
    setSectionsStates([ sections, setSections ]);
    setSharing(sharing);
    setSectionIndex(Object.keys(sections).length);
    setOpenSidebar("newSection");
  }
  

  return (
    <>
      <PageNav/>
      <GlobalMenus page_id={null}/>
      
      <div className="page_wrapper" style={{width: pageWidth}}>
        
        {sections.length !== 0 
          ?　<div 
              className="page_area"
              // style={{width: pageWidth}}
              css={SettingCSS}
            >
              <div className="page_label">{sharing == "header" ? "ヘッダー" : "フッター"}</div>
              <Sections 
                page_id={null}
                sections={sections} 
                setSections={setSections} 
                sharing={sharing}
                key={sharing}
              />
            </div>
          : <div className="wrapper">
              <div className="page_setting_box" style={{padding:"1.5rem 3.5rem 1.5rem"}}>
                <div className="page_label" style={{left:"0"}}>{sharing == "header" ? "ヘッダー" : "フッター"}</div>
                共通{sharing == "header" ? "ヘッダー" : "フッター"}はありません。<br/>
                下記のボタンよりブロックを追加してください。
              </div>
            </div>
        }

        <div className="wrapper mb_4">
          <button className="btn_add" onClick={newSectionHandler}>
            <span><BiPlus/>ブロックを追加</span>
          </button>
        </div>
      </div>
      
      {/*
      { open &&
        <NewSection
          page_id={null} 
          sections={sections} 
          setSections={setSections}
          sharing={sharing}
          index={Object.keys(sections).length}
          key={sharing}
          setOpen={setOpen}
        />
      }
      */}
    </>
  );
});

