import React, { useContext } from "react"
import { useParams, Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { BiX } from "react-icons/bi";
import { SpinBtn } from '@/editerMenu/SpinBtn';
import { RangeSpin } from '@/editerMenu/RangeSpin';
import { RangeSpinSplit } from '@/editerMenu/RangeSpinSplit';
import { AddonJudg } from '@/addon/AddonJudg';

import { useRecoilState } from 'recoil'
import { blocksStatesAtom, sectionStatesAtom } from '@/providers/Atoms';


export const BlockGlobalSetting = React.memo(({ blockState, InputChange, Clear, 
　paddings, paddingChange, paddingClear, setStates, setOpenSidebar }) => {

  // console.log("ブロック設定レンダ");
  const [ blocksStates, setBlocksStates ] = useRecoilState(blocksStatesAtom);
  const [ sectionStates ] = useRecoilState(sectionStatesAtom);
  
  // const blocks = blocksStates[0]
  const setBlocks = blocksStates[1]
  const { block, setBlock } = blockState
  // console.log("ブロックズ", blocks);
  // console.log("セッションID", sectionStates[0].id);
  
  const { productId, blockId, slug } = useParams();
  const { setToastObj } = useContext(ToastContext);
  const { client, currentUser } = useContext(StateContext);

  const CloseHandler = ()=> {
    setOpenSidebar("");
  };

  
  //ブロックを共有化
  const sharingBlock = () => {
    // const newBlock = { ...block, sharing: "sharing" }
    client.patch(`/api/v1/blocks/${block.id}`, { ...block, sharing: "sharing" })
    .then(resp => {
      // setBlock(resp.data);
      setStates(resp.data);
      setToastObj({message: "共有化しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };

  
  // 共有ブロックを外す
  const offSharing = () => {
    const sure = window.confirm(`この共有ブロックを本当に除外しますか?`);
    if (sure) {
      client.post(`/api/v1/blocks/off_block`, {section_id: sectionStates[0].id, block_id: block.id})
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
        setToastObj({message: "共有ブロックを除外しました。", color: "#D1E7DD"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  
  // 削除
  const deleteBlock = () => {
    const sure = window.confirm(`（${block.id}）このブロックを本当に削除しますか?`);
    if (sure) {
      client.delete(`/api/v1/blocks/${block.id}`)
      .then(resp => {
        setBlocks((prev) =>
          prev.filter((obj) => obj.id !== block.id)
        );
        // setToastObj({message: "ブロックを削除しました。", color: "#ffedab"});
      })
      .catch(e => {
        setToastObj({message: "【エラー】削除に失敗しました。", color: "#ffedab"});
        console.log(e);
      });
    }
  };
  
  // const pageData = {
  //   subdomain: productId,
  //   slug: slug,
  // }
    
  // 固定&解除
  const fixed = (type) => {
    client.patch(`/api/v1/blocks/fixed/${block.id}`, {
        subdomain: productId,
        slug: slug, 
        block_type: type
      })
    .then(resp => {
      // setBlock(resp.data);
      setStates(resp.data);
      
      if (resp.data.block_type == "fixed_header") {
        setToastObj({message: "上部に固定しました。", color: "#D1E7DD"});
      } else if (resp.data.block_type == "fixed_footer") {
        setToastObj({message: "下部に固定しました。", color: "#D1E7DD"});
      } else if (resp.data.block_type == null){
        setToastObj({message: "固定を解除しました。", color: "#D1E7DD"}); 
      }
    })
    .catch(e => {
      setToastObj({message: `${e.response.data}`, color: "#ffedab"});
      console.log(e);
    });
  };
  
  // 幅いっぱいにする
  const fullWidth = () => {
    const newBlock = {...block, wrapper: 2000};
    // setBlock(newBlock);
    setStates(newBlock);
  }

  
  // paddingの分割
  // let paddings = block.padding ? block.padding.split("／") : [];


// <div className="cover" onClick={CloseHandler}/>
  return (
    <>
      <div className="items_bar">
      
        <div className="items_bar_top">
          <div className="sidebar_title">
            ブロック設定
            <div className="close_btn" onClick={CloseHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <label>ステータス</label>
          <div className="radio_box">
            {["公開", "非公開"].map((item) => {
              return (
                <label key={item}
                  className={item == block.status ? "is_on": ""}
                  // style={{border: item == block?.status && "1px solid #00ff2c"}}
                >
                  <input
                    type="radio"
                    id={item}
                    name="status"
                    value={item}
                    onChange={InputChange}
                    checked={item == block?.status}
                  />
                  {item}
                </label>
              );
            })}
          </div>
          
          <div className="input_fields">
            <div className="field_2">
              <label>横並び数（カラム）</label>
              <SpinBtn
                obj={block}
                setObj={setBlock}
                column="column"
                objValue={block.column ||1}
                InputChange={InputChange}
                setStates={setStates}
              />
            </div>
          
            <div className="field_2">
              <label>スマホ時
                <div className="btn_clear" type="button" onClick={()=>Clear("column_s")}>クリア</div>
              </label>
              <SpinBtn
                obj={block}
                setObj={setBlock}
                column="column_s"
                objValue={block.column_s ||1}
                InputChange={InputChange}
                setStates={setStates}
              />
            </div>
          </div>
          
          <label>内側のスペース【上】（{paddings[0] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_top")}>クリア</div>
          </label>
          <RangeSpinSplit
            obj={block}
            setObj={setBlock}
            InputChange={paddingChange}
            targetName="padding"
            column="padding_top"
            objValueMain={paddings[0] ||100}
            objValueOther={paddings[1] ||""}
            beforeAfter="before"
            max="200"
            setStates={setStates}
          />
          
          <label>内側のスペース【下】（{paddings[1] ||""}px）
            <div className="btn_clear" type="button" onClick={()=>paddingClear("padding_bottom")}>クリア</div>
          </label>
          <RangeSpinSplit
            obj={block}
            setObj={setBlock}
            InputChange={paddingChange}
            targetName="padding"
            column="padding_bottom"
            objValueMain={paddings[1] ||100}
            objValueOther={paddings[0] ||""}
            beforeAfter="after"
            max="200"
            setStates={setStates}
          />
          
          <div className="form_field">
            <label>全体幅（{block.wrapper ||""}px）
              <div className="btn_clear" type="button" onClick={()=>Clear("wrapper")}>クリア</div>
            </label>
            <RangeSpin
              obj={block}
              setObj={setBlock}
              column="wrapper"
              objValue={block.wrapper ||1150} 
              InputChange={InputChange}
              max="2000"
              min="300"
              setStates={setStates}
            />

            <p style={{color:"#03c494", textAlign:"right"}} onClick={fullWidth}>
              横幅いっぱいにする
            </p>
          </div>
          
          <label>高さ（{block.height ||""}vh）
            <div className="btn_clear" type="button" onClick={()=>Clear("height")}>クリア</div>
          </label>
          <RangeSpin
            obj={block}
            setObj={setBlock}
            column="height"
            objValue={block.height ||55} 
            InputChange={InputChange}
            min="10"
            setStates={setStates}
          />

          { !blockId &&
            <div className="form_field">
              <label>ブロックの固定（上部 又は 下部）</label>
              {/fixed/.test(block.block_type)
                ? <button className="btn_line w_100" onClick={()=>fixed(null)}>固定を解除する</button>
                : <>
                    <button className="btn_line mr_1" onClick={()=>fixed("fixed_header")}>上部に固定</button>
                    <button className="btn_line" onClick={()=>fixed("fixed_footer")}>下部に固定</button>
                  </>
              }
              <p>固定の確認は「公開ページを見る」で確認ください。この編集ページでは固定されません。</p>
            </div>
          }
          
          
          <AddonJudg terms={/|スタンダード|ビジネス|共有ブロック/} boolean={true}>
            { !blockId &&
              <div className="form_field">
                { block.sharing !== "sharing"
                  ? 
                    <>
                      <label>ブロックの共有化</label>
                      <button className="btn_line w_100" onClick={sharingBlock}>共有化する</button>
                    </>
                  : 
                    <>
                      { block.sharing == "sharing" &&
                        <>
                          <label>共有化済みブロック</label>
                          <button className="btn_line mr_1" onClick={()=>Clear("sharing")}>共有を解除</button>
                          <button className="btn_line" onClick={offSharing}>除外する</button>
                          <div className="mt_1">
                            <p>「除外する」とは削除されるわけではありません。共有ブロックがこのページで表示されなくなるだけです。</p>
                            <Link to={`/product/${productId}/block/${block.id}`} className="">編集・削除はこちら</Link>
                          </div>
                        </>
                      }
                    </>
                }
              </div>
            }
          </AddonJudg>

          
          <AddonJudg terms={/ビジネス/} boolean={true}>
            <label>id追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_id")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_id"
              value={block?.add_id || ""} 
              onChange={InputChange}
              placeholder="追加するidを入力..."
            />
            
            <label>class追加
              <div className="btn_clear" type="button" onClick={()=>Clear("add_class")}>クリア</div>
            </label>
            <input 
              className="input" 
              type="text"
              name="add_class"
              value={block?.add_class || ""} 
              onChange={InputChange}
              placeholder="追加するclassを入力..."
            />
          </AddonJudg>
          
          { block.sharing !== "sharing" &&
            <>
              <label>ブロックの削除</label>
              <button className="btn_delete w_100" onClick={deleteBlock}>削除する</button>
            </>
          }
          
        </div>
        
        <div className="items_bar_bottom">
        </div>

      </div>
    </>
  );
});